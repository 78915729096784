const parseScreenshotQueryParameters = (href: string): {cogUrl: string, bbox?: string, legendTitle?: string, colorBreaks: string} => {
  // Get this page's query parameters
  const searchParams = new URLSearchParams(new URL(href).search).entries();

  const {cogUrl, bbox, legendTitle, colorBreaks} = Object.fromEntries(searchParams);
  Object.entries({cogUrl, colorBreaks}).map(([key, value]) => {
    if (!value) {
      throw new Error(`GET parameter ${key} is mandatory`);
    }
  });

  return {cogUrl, bbox, legendTitle, colorBreaks};
};

export default parseScreenshotQueryParameters;
