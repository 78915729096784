import React, {CSSProperties, FC} from 'react';

type Props = {
  top: number
  left: number
  children: React.ReactNode
};

const Tooltip: FC<Props> = ({top, left, children}) => {
  const tooltipStyle: CSSProperties = {
    background: '#ffffff',
    borderRadius: 2,
    width: 50,
    height: 30,
    padding: 5,
    position: 'fixed',
    top: top - 40,
    left: left - 25
  };

  return <div style={tooltipStyle}>{children}</div>;
};

export default Tooltip;