import React, {FC, useState} from 'react';

import styled from '@mui/system/styled';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';

import SidePanel from '@geomatico/geocomponents/Layout/SidePanel';

import {
  DRAWER_WIDTH,
} from '../config';

import theme from '../theme';

export type MainProps = {
  widescreen: boolean,
  isLeftDrawerOpen: boolean
}

const Main = styled(Box)<MainProps>(({isLeftDrawerOpen}) => ({
  flexGrow: 1,
  padding: 0,
  position: 'absolute',
  top: 0,
  bottom: 0,
  right: 0,
  left: +(isLeftDrawerOpen && DRAWER_WIDTH)
})) as React.ElementType;


export type LayoutProps = {
  mainContent: React.ReactElement,
  sidePanelContent: React.ReactElement,
  miniSidePanelSelectedActionId?: string
};

const Layout:  FC<LayoutProps> = ({mainContent, sidePanelContent}) => {
  const widescreen = useMediaQuery(theme().breakpoints.up('sm'), {noSsr: true});
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(true);

  const handleClose = () => setIsSidePanelOpen(!isSidePanelOpen);

  const sidePanelSx = {
    '& .MuiPaper-root': {
      top: 0,
      height: '100%',
      p: 0,
    }
  };

  return <>
    {sidePanelContent && isSidePanelOpen &&
      <SidePanel
        drawerWidth={DRAWER_WIDTH + 'px'}
        anchor="left"
        isOpen={isSidePanelOpen}
        onClose={handleClose}
        widescreen={widescreen}
        sx={sidePanelSx}
      >
        {sidePanelContent}
      </SidePanel>
    }
    <Box>
      <Main widescreen={widescreen.toString()} isLeftDrawerOpen={sidePanelContent && isSidePanelOpen}>
        {mainContent}
      </Main>
    </Box>
    
  </>;
};

export default Layout;
