import React, {FC, SVGProps} from 'react';

const LogoTalptechBlanco: FC<SVGProps<SVGSVGElement>> = (props) =>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 850.39 97.927"
    height="100%"
    {...props}
  >
    <path
      fill="#FFF"
      d="M130.583 55.907 143.5 24.189a6.373 6.373 0 0 1 6.083-4.354 6.43 6.43 0 0 1 6.257 4.354l13.522 33.014 13.812 32.898c.259.635.49 1.211.691 1.816h22.23c0-.346-.26-.664-.404-1.01l-32.407-74.85C168.613 5.246 160.828 0 149.468 0c-11.359 0-19.231 5.104-23.96 16.059L93.359 90.823l-.403 1.01h22.778c.201-.664.432-1.27.634-1.846l5.046-12.197 9.169-21.883zM242.684 92.61h62.941V73.034h-60c-7.468 0-10.265-3.057-10.265-11.217V2.854h-19.606v61.329c0 20.73 7.295 28.427 26.93 28.427zM410.576 57.059c10.479-13.35 9.633-32.352-1.989-44.719-6.171-6.459-14.215-9.486-25.315-9.486h-59.511c-2.652 0-3.575.951-3.575 3.576v86.18h19.491V68.881h43.595c11.735 0 21.047-3.978 27.304-11.822zm-70.899-29.553a3.922 3.922 0 0 1 4.008-4.239h40.106a14.414 14.414 0 0 1 12.022 5.045 13.98 13.98 0 0 1 2.739 8.65 13.552 13.552 0 0 1-2.537 8.045 13.75 13.75 0 0 1-12.225 5.391h-44.114V27.506z"
    />
    <path d="M631.693 23.268V2.854H552.26c-7.237 0-10.467 3.229-10.467 10.408v68.594c0 7.496 3.201 10.84 10.352 10.84h79.549V73.12h-65.998a4.326 4.326 0 0 1-4.412-4.787V54.608h68.247V41.027h-68.247V27.996a4.237 4.237 0 0 1 4.412-4.643l65.997-.085zM736.385 23.268V2.854h-44.748a43.971 43.971 0 0 0-31.716 11.793c-17.3 17.182-18.553 44.752-2.884 63.432 8.362 9.658 19.952 14.416 34.397 14.416h44.95V72.313h-44.748c-13.376.924-24.969-9.17-25.893-22.547a24.432 24.432 0 0 1-.057-1.934c-.259-13.358 10.36-24.395 23.718-24.655.744-.014 1.489.006 2.231.061l44.75.03zM850.389 92.61V2.854h-19.635V37.02h-58.098V2.854H753.05V92.61h19.606V56.194h58.098V92.61zM477.612 21.941a388.603 388.603 0 0 0 50.024-4.643V2.479h-99.155v14.07a242.406 242.406 0 0 0 49.131 5.392zM477.67 33.1c-3.057 0-6.055 0-9.342-.174V92.61h19.606V32.84c-3.748 0-7.121.201-10.264.26z" />
    <path
      fill="#FFF"
      d="M51.898 22.691a407.887 407.887 0 0 0 51.178-4.816V1.758H2.335v15.484a240.117 240.117 0 0 0 49.563 5.449zM51.898 32.379c-2.883 0-5.997 0-9.659-.203v61.213h21.077V31.945c-4.295.231-8.045.375-11.418.434z"
    />
  </svg>;

export default LogoTalptechBlanco;
