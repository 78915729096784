import {HEXColor} from '../entities/HEXColor';

type ThresholdScale = {
  thresholds: Array<number>,
  colors: Array<HEXColor>
}

const parseColorBreaks = (colorBreaks: string): ThresholdScale => {
  const elements = colorBreaks.split(',');

  if (elements.length % 2 == 0) {
    throw new Error('colorBreaks parameter needs an odd number of elements');
  }

  if (elements.length < 1) {
    throw new Error('colorBreaks parameter needs at least 1 element');
  }

  return elements.reduce<ThresholdScale>(
    (spec, element, currentIndex) => {
      const isColor = currentIndex % 2 === 0;
      if (isColor) {
        spec.colors.push(element as HEXColor);
      } else {
        const threshold = Number(element);
        if (isNaN(threshold)) {
          throw new Error(`colorBreaks threshold value '${element}' is not numeric`);
        }
        spec.thresholds.push(threshold);
      }
      return spec;
    },
    {thresholds: [], colors: []}
  );
};

export default parseColorBreaks;