import React, {forwardRef, Ref, useState} from 'react';
import {MapRef} from 'react-map-gl';
import maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
import {cogProtocol} from '@geomatico/maplibre-cog-protocol';
import Map, {MapProps} from '@geomatico/geocomponents/Map/Map';
import {INITIAL_VIEWPORT, MAPSTYLE_URL} from '../config';

maplibregl.addProtocol('cog', cogProtocol);
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore needed for react-map-gl version compatibility.
// Was fixed in react-map-gl 7.0.24 but @geomatico/geocomponents needs to update its dependencies.
// TODO remove when future geocomponent release fixes https://github.com/visgl/react-map-gl/issues/2176
maplibregl.supported = () => true;

const TalptechMap = forwardRef(
  function TalptechMap(props: MapProps, ref: Ref<MapRef>)  {
    const [viewport, setViewport] = useState(INITIAL_VIEWPORT);
    return <Map
      ref={ref}
      mapLib={maplibregl}
      mapStyle={MAPSTYLE_URL}
      maxZoom={18}
      viewport={viewport}
      onViewportChange={setViewport}
      {...props}
    />;
  }
);

export default TalptechMap;
