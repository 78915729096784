import React, {FC, useState} from 'react';

import styled from '@mui/system/styled';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import LogoTalptechBlanco from './logos/LogoTalptechBlanco';

import ResponsiveHeader from '@geomatico/geocomponents/Layout/ResponsiveHeader';
import SidePanel from '@geomatico/geocomponents/Layout/SidePanel';

import {
  DRAWER_WIDTH,
} from '../config';

import theme from '../theme';

export type MainProps = {
  widescreen: boolean,
  isLeftDrawerOpen: boolean
}

const Main = styled(Box)<MainProps>(({isLeftDrawerOpen}) => ({
  flexGrow: 1,
  padding: 0,
  position: 'absolute',
  top: 56,
  '@media (min-width: 0px) and (orientation: landscape)': {
    top: 48
  },
  bottom: 0,
  right: 0,
  left: +(isLeftDrawerOpen && DRAWER_WIDTH)
})) as React.ElementType;

const responsiveHeaderSx = {
  '&.MuiAppBar-root': {
    zIndex: 1500
  }
};

export type LayoutProps = {
  mainContent: React.ReactElement,
  sidePanelContent: React.ReactElement,
  miniSidePanelSelectedActionId?: string
};

const Layout:  FC<LayoutProps> = ({mainContent, sidePanelContent}) => {
  const widescreen = useMediaQuery(theme().breakpoints.up('sm'), {noSsr: true});
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(true);

  const handleClose = () => setIsSidePanelOpen(!isSidePanelOpen);

  return <>
    <ResponsiveHeader
      title='Democratizing Precision Agriculture'
      logo={<LogoTalptechBlanco height='65%' style={{margin: '10px 20px 0 20px'}}/>}
      onStartIconClick={widescreen ? undefined : handleClose}
      isStartIconCloseable={isSidePanelOpen}
      sx={responsiveHeaderSx}
    >
    </ResponsiveHeader>
    {sidePanelContent && isSidePanelOpen &&
      <SidePanel
        drawerWidth={DRAWER_WIDTH + 'px'}
        anchor="left"
        isOpen={isSidePanelOpen}
        onClose={handleClose}
        widescreen={widescreen}
      >
        {sidePanelContent}
      </SidePanel>
    }
    <Box sx={{outline: '12px solid red'}}>
      <Main widescreen={widescreen} isLeftDrawerOpen={sidePanelContent && isSidePanelOpen}>
        {mainContent}
      </Main>
    </Box>
    
  </>;
};

export default Layout;
