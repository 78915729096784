import {Field} from '../entities/Field';
import {inject} from '../../common/injection';

import FieldRepository from '../../repository/fields/FieldRepository';

const getAllFields = async (): Promise<Array<Field> | undefined> => {

  const repository = inject(FieldRepository);
  const fields = await repository.list();

  return fields.map(field => {
    return {
      ...field,
      area: field.area / 10000
    };
  });
};

export default getAllFields;