import {AnyLayer, Sources} from 'mapbox-gl';
import {FeatureCollection, MultiPolygon} from 'geojson';
import {SurveyDataType, SurveyDataUrls, SurveyVariable} from '../entities/Survey';
import {FIELDS_LAYERS, FIELDS_SOURCE, VALUE_RANGE_BY_VARIABLE} from '../../config';

export const COG_SOURCE = 'cog';

const getMapSourcesAndLayers = (
  fields: FeatureCollection<MultiPolygon>, surveyDataUrls?: SurveyDataUrls, selectedVariable?: SurveyVariable
): [Sources, Array<AnyLayer>] => {
  const sources = getSources(fields, surveyDataUrls, selectedVariable);
  return [sources, getLayers(sources)];
};

const getSources = (
  fields: FeatureCollection<MultiPolygon>, surveyDataUrls?: SurveyDataUrls, selectedVariable?: SurveyVariable
) => {
  const sources: Sources = {
    [FIELDS_SOURCE]: {
      type: 'geojson',
      data: fields
    }
  };

  const cogUrl = surveyDataUrls?.urls
    .find(url => url.variable === selectedVariable && url.type === SurveyDataType.COG)?.url;
  if (cogUrl && selectedVariable) {
    // TODO TALP-65 for some variables we might want to adjust min/max to the actual values in the cog
    const [min, max] = VALUE_RANGE_BY_VARIABLE[selectedVariable];
    sources[COG_SOURCE] = {
      type: 'raster',
      url: `cog://${cogUrl}#color:BrewerSpectral5,${min},${max}`,
      tileSize: 256
    };
  }

  return sources;
};

const getLayers = (sources: Sources) => {
  const layers: Array<AnyLayer> = [...FIELDS_LAYERS];
  if (sources[COG_SOURCE]) {
    layers.push({
      'id': `${COG_SOURCE}-layer`,
      'source': COG_SOURCE,
      'type': 'raster'
    });
  }
  return layers;
};

export default getMapSourcesAndLayers;
