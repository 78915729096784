import {Constructable, Container, Service} from 'typedi';

// syntactic sugar
export const Repository = (target: unknown) => Service()(target);
export const HttpService = (target: unknown) => Service()(target);

export const inject = <T>(type: Constructable<T>): T => {
  try {
    return Container.get(type);
  } catch (e) {
    console.error('Cannot inject type: ' + type.toString());
    console.error(e);
    throw e;
  }
};
