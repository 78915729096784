import {BBOX} from '../entities/BBOX';

function parse4326Bbox(param: string): BBOX {
  const arr = param.split(',');

  if (arr.length !== 4) {
    throw new Error(`bbox param '${param}' is not valid, expected 4 comma-separated elements`);
  }

  const [xmin, ymin, xmax, ymax] = arr.map(v => parseFloat(v));

  if ([xmin, ymin, xmax, ymax].some(value => isNaN(value))) {
    throw new Error(`bbox param '${param}' is not valid, expected all elements to be numeric`);
  }

  if (xmin >= xmax) {
    throw new Error(`bbox xmin value '${xmin}' should be less than xmax value ${xmax}`);
  }
  if (ymin >= ymax) {
    throw new Error(`bbox ymin value '${ymin}' should be less than ymax value ${ymax}`);
  }
  if (xmin < -180 || xmin > 180) {
    throw new Error(`bbox xmin value '${xmin}' should be between -180 and 180 degrees`);
  }
  if (xmax < -180 || xmax > 180) {
    throw new Error(`bbox xmax value '${xmax}' should be between -180 and 180 degrees`);
  }
  if (ymin < -90 || ymin > 90) {
    throw new Error(`bbox ymin value '${ymin}' should be between -90 and 90 degrees`);
  }
  if (ymax < -90 || ymax > 90) {
    throw new Error(`bbox ymax value '${ymax}' should be between -90 and 90 degrees`);
  }

  return [xmin, ymin, xmax, ymax];
}

export default parse4326Bbox;
