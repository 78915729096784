import React, {FC} from 'react';

import {VegaLite} from 'react-vega';

import {MultiPolygon} from 'geojson';
import {FIELDS_COLOR} from '../config';

export interface GeometryThumbnailProps {
  size?: number,
  borderColor?: string,
  color?: string,
  opacity?: number,
  boundary: MultiPolygon
}

const GeometryThumbnail: FC<GeometryThumbnailProps> = ({
  size = 25,
  borderColor = FIELDS_COLOR,
  color = FIELDS_COLOR,
  opacity = 1,
  boundary
}) => {

  const spec = {
    $schema: 'https://vega.github.io/schema/vega-lite/v5.json',
    width: size,
    height: size,
    layer: [
      {
        mark: {
          type: 'geoshape',
          stroke: borderColor,
          fill: color,
          fillOpacity: opacity,
          strokeWidth: 1
        },
        data: {
          values: [
            {
              type: 'Feature',
              properties: {},
              geometry: boundary
            }
          ],
          format: {
            type: 'json'
          }
        },
        projection: {
          type: 'mercator'
        }
      }
    ],
    config: {
      background: 'transparent'
    }
  };
  return <div style={{minWidth: 70}}>
    <VegaLite spec={spec} actions={false}/>
  </div>;
};

export default GeometryThumbnail;
