import {VersionedEntity, VersionedEntityCreation} from './VersionedEntity';
import {VersionedEntityDto, VersionedEntityDtoCreation} from './VersionedEntityDto';

interface RepositoryMapper<TDomain extends VersionedEntity, TDto extends VersionedEntityDto> {
  toDomain: (dto: TDto) => TDomain,
  toDto: (domain: TDomain) => TDto,
  toDtoCreation: (domain: VersionedEntityCreation<TDomain>) => VersionedEntityDtoCreation<TDto>,
}

export function toEnum<EnumType>(enumDef: { [key in string]: EnumType }, text: string): EnumType {
  return enumDef[text as string] as EnumType;
}

export default RepositoryMapper;
