export type HttpError = {
  statusCode: number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any
}

type BaseRequest = {
  baseUrl: string
  path: string
  headers?: Record<string, string>
}

export type GetRequest = BaseRequest & {
  queryParams?: Record<string, string>
}

export type PostRequest<RequestType> = BaseRequest & {
  body?: RequestType
}

export type PutRequest<RequestType> = PostRequest<RequestType>;

export type DeleteRequest = BaseRequest;

export const doFetch = async <RequestType, ResponseType>(url: string, method: string, headers?: Record<string, string>, body?: RequestType): Promise<ResponseType> => {
  const request: RequestInit = {
    method,
    headers: {
      'Content-Type': 'application/json',
      // TODO change when implementing PATCH
      'Accept': 'application/json',
      ...(headers || {})
    },
  };
  const response = await fetch(url, request);
  if (body) {
    request.body = JSON.stringify(body);
  }

  if (response.status === 204 || Number(response.headers.get('content-length')) === 0) {
    return undefined as ResponseType;
  } else if (response.status >= 200 && response.status < 300 && response.ok) {
    return response.json();
  } else {
    // WARNING this can't be expressed in Typescript, but: Statuses other than 2xx are rejected with an HttpError.
    const httpError: HttpError = {
      statusCode: response.status,
      payload: await response.text()
    };
    try {
      httpError.payload = JSON.parse(httpError.payload);
    } catch {
      // Fine, can't parse response as JSON, will assume plain text
    }
    throw httpError;
  }
};

const getUrl = (baseUrl: string, path?: string, queryParams?: Record<string, string>) => {
  let url = baseUrl;
  if (!baseUrl.endsWith('/')) {
    url += '/';
  }
  url += path || '';
  if (queryParams) {
    url += '?' + new URLSearchParams(queryParams).toString();
  }
  return url;
};

export const get = <ResponseType>({baseUrl, path, queryParams, headers}: GetRequest): Promise<ResponseType> => {
  const url = getUrl(baseUrl, path, queryParams);
  return doFetch(url, 'GET', headers);
};

export const post = <RequestType, ResponseType>(
  {baseUrl, path, body, headers}: PostRequest<RequestType>
): Promise<ResponseType> => {
  const url = getUrl(baseUrl, path);
  return doFetch(url, 'POST', headers, body);
};

export const put = <RequestType, ResponseType>(
  {baseUrl, path, body, headers}: PutRequest<RequestType>
): Promise<ResponseType> => {
  const url = getUrl(baseUrl, path);
  return doFetch(url, 'PUT', headers, body);
};

// delete is keyword :(
export const destroy = ({baseUrl, path, headers}: DeleteRequest): Promise<void> => {
  const url = getUrl(baseUrl, path);
  return doFetch(url, 'DELETE', headers);
};
