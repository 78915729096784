import React, {FC, useMemo} from 'react';

//MUI
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

import i18n from 'i18next';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import {SelectChangeEvent, SxProps} from '@mui/material';

const selectSx: SxProps = {
  border: 'none',
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
};

//TYPES
export type SurveyDatePickerProps = {
  availableDates: Array<Date>,
  selectedDate: Date,
  onDateChange: (date: Date) => void
};

const SurveyDatePicker: FC<SurveyDatePickerProps> = ({availableDates, selectedDate, onDateChange}) => {

  const lang = i18n.resolvedLanguage;

  const sortedAvailableDates = availableDates.sort((a, b) => a.getTime() - b.getTime());
  const selectedIndex = useMemo(() => sortedAvailableDates.findIndex(date => date.getTime() === selectedDate.getTime()), [sortedAvailableDates, selectedDate]);

  const handleDateChange = (e: SelectChangeEvent<string>) => onDateChange(new Date(Date.parse(e.target.value)));
  const handleNextDate = () => onDateChange(sortedAvailableDates[selectedIndex+1]);
  const handlePreviousDate = () => onDateChange(sortedAvailableDates[selectedIndex-1]);

  return <Card sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', bgcolor: 'white', borderRadius: '4px', minWidth: 225, opacity: 0.75}}>
    <IconButton color='primary' onClick={handlePreviousDate} disabled={selectedIndex === 0}>
      <KeyboardArrowLeftIcon />
    </IconButton>
    <FormControl size='small' sx={{minWidth: 130}}>
      <Select variant='outlined' value={selectedDate.toISOString()} onChange={handleDateChange} sx={selectSx}>
        {
          sortedAvailableDates.map((date, index) => 
            <MenuItem value={date.toISOString()} key={index}>{date.toLocaleDateString(lang === 'es' ? 'es-Es' : 'en-Us')}</MenuItem>
          )
        }
      </Select>
    </FormControl>
    <IconButton color='primary' onClick={handleNextDate} disabled={selectedIndex === sortedAvailableDates.length-1}>
      <KeyboardArrowRightIcon />
    </IconButton>
  </Card>;
};
export default SurveyDatePicker;