import {ColorFunction} from '@geomatico/maplibre-cog-protocol/dist/types';
import {scaleThreshold} from 'd3-scale';
import {HEXColor} from '../entities/HEXColor';
import hexToRGBA from './hexToRGBA';

const getThresholdColorFunction = (thresholds: Array<number>, colors: Array<HEXColor>): ColorFunction => {
  if (thresholds.length !== colors.length - 1) {
    throw new Error('Threshold based color function needs N+1 colors for N thresholds');
  }

  const interpolate = scaleThreshold(thresholds, colors.map(hexToRGBA));

  return ([value], rgba, {noData}) => {
    if (value === noData || value === Infinity || isNaN(value)) {
      rgba.set([0, 0, 0, 0]); // noData, fillValue or NaN pixel values => transparent
    } else {
      rgba.set(interpolate(value));
    }
  };
};

export default getThresholdColorFunction;
