import 'reflect-metadata';

import {AnyLayer} from 'mapbox-gl';
import {SurveyVariable} from './domain/entities/Survey';

export const DRAWER_WIDTH = 450;

export const INITIAL_VIEWPORT = {
  latitude: 41.6779,
  longitude: 0.4936,
  zoom: 12.5,
  bearing: 0,
  pitch: 0
};

export const MAPSTYLE_URL = '/mapstyles/pnoa.json';

export const FIELDS_COLOR = 'hsl(337, 155%, 27%)';
export const FIELDS_COLOR_DARK = 'hsl(337, 155%, 12%)';

export const FIELDS_SOURCE = 'fields';
export const FIELDS_LAYERS: Array<AnyLayer> = [
  {
    'id': 'fields-outline',
    'source': FIELDS_SOURCE,
    'type': 'line',
    'paint': {
      'line-color': FIELDS_COLOR,
      'line-width': ['interpolate', ['linear'], ['zoom'], 13, 1, 15, 2, 16, 3]
    }
  },
  {
    'id': 'fields-fill',
    'source': FIELDS_SOURCE,
    'type': 'fill',
    'paint': {
      'fill-color': FIELDS_COLOR,
      'fill-opacity': 0.25,
    }
  },
  {
    'id': 'fields-label',
    'source': FIELDS_SOURCE,
    'type': 'symbol',
    'minzoom': 12,
    'layout': {
      'text-field': ['get', 'name'],
      'text-size': ['interpolate', ['linear'], ['zoom'], 10, 8, 18, 20, 22, 24],
      'text-font': ['Roboto Condensed Bold'],
    },
    'paint': {
      'text-color': FIELDS_COLOR_DARK,
      'text-halo-color': '#FFF',
      'text-halo-width': 1
    }
  }
];

export const VALUE_RANGE_BY_VARIABLE: { [key in SurveyVariable]: [number, number]} = {
  [SurveyVariable.LAI_MEAN_LEFT]: [0.0, 1.0],
  [SurveyVariable.LAI_MEAN_RIGHT]: [0.0, 1.0],
  [SurveyVariable.SPEED_MEAN]: [5.0, 20.0],
  [SurveyVariable.PRESSURE_MEAN]: [-10.0, 15.0],
};

export const API_BASE_URL = process.env.API_BASE_URL || '';

export const unitsVariable = [
  {
    variable: 'LAI_MEAN_LEFT',
    unit: ''
  },
  {
    variable: 'LAI_MEAN_RIGHT',
    unit: ''
  },
  {
    variable: 'SPEED_MEAN',
    unit: 'km/h'
  },
  {
    variable: 'PRESSURE_MEAN',
    unit: 'bar'
  }
];
