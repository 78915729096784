import React, {CSSProperties} from 'react';
import {useTranslation} from 'react-i18next';

const styles: Record<string, CSSProperties> = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    height: '100vh',
    padding: '20px',
    backgroundColor: '#f9f9f9'
  },
  title: {
    fontSize: '2rem',
    marginBottom: '20px',
    color: '#333'
  },
  image: {
    width: '300px',
    height: 'auto',
    marginBottom: '20px',
    borderRadius: 20,
    borderColor: '1px solid #555'
  },
  message: {
    fontSize: '1.2rem',
    marginBottom: '20px',
    color: '#555'
  },
  link: {
    fontSize: '1rem',
    color: '#007bff',
    textDecoration: 'none',
    fontWeight: 'bold'
  }
};

const NotFoundView = () => {
  const {t} = useTranslation();

  return <div style={styles.container}>
    <h1 style={styles.title}>404 - {t('notFoundTitle')}</h1>
    <img
      src="/img/404.webp"
      alt="Friendly farmer on a tractor"
      style={styles.image}
    />
    <p style={styles.message}>
      {t('notFoundDescription')}
    </p>
    <a href="/" style={styles.link}>
      {t('goBackHome')}
    </a>
  </div>;
};

export default NotFoundView;
