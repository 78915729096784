import {Field} from '../entities/Field';
import {Survey} from '../entities/Survey';
import {inject} from '../../common/injection';
import SurveyRepository from '../../repository/survey/SurveyRepository';

const getDoneSurveys = async (field?: Field): Promise<Survey[] | undefined> => {
  if (!field) return;
  const repository = inject(SurveyRepository);
  const surveys = await repository.list([field.uuid]);
  return surveys.filter(survey => survey.status === 'DONE');
};

export default getDoneSurveys;