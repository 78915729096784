import React, {FC} from 'react';

//MUI
import {
  DataGrid,
  GridRenderCellParams,
  GridRowSelectionModel,
  GridColDef,
} from '@mui/x-data-grid';

import Box from '@mui/material/Box';
import {enUS, esES} from '@mui/x-data-grid/locales';

import { lighten } from '@mui/material/styles';

//TALPTECH
import {Field} from '../domain/entities/Field';
import GeometryThumbnail from './GeometryThumbnail';

import i18n from 'i18next';
import {useTranslation} from 'react-i18next';


export interface FieldsTableProps {
  fields: Array<Field>,
  selectedField?: Field,
  onSelectedField: (field: Field) => void
}

const FieldsTable: FC<FieldsTableProps> = ({fields, selectedField, onSelectedField}) => {
  
  const {t} = useTranslation();
  const lang = i18n.resolvedLanguage;

  const handleSelectedField = (selectedRowId: GridRowSelectionModel) => {
    const field: Field | undefined = fields.find(field => field.uuid === selectedRowId[0]);
    field && onSelectedField(field);
  };
  
  //STYLES
  const listStyle = {
    fontSize: '14px',
    boxShadow: 2,
    '& .MuiDataGrid-row.Mui-selected': {
      backgroundColor: lighten('#5c707e',0.25),
      color: 'white',
      '&:hover': {
        backgroundColor: lighten('#5c707e',0.35),
        color: 'white',
      }
    },
    '& .MuiDataGrid-topContainer': {
      borderRadius: '4px'
    },
    '& .MuiDataGrid-scrollbarFiller': {
      backgroundColor: lighten('#7e6a5c', 0.65),
    },
    '& .MuiDataGrid-container--top [role=row]': {
      backgroundColor: 'transparent',
    },
    '& .MuiDataGrid-columnHeader:last-child': {
      borderRadius: '40px',
    },
    '& .MuiDataGrid-columnHeaders': {
      backgroundColor: lighten('#7e6a5c', 0.65),
      fontSize: '14px',
    },
    '& .MuiDataGrid-virtualScroller': {
      // Undo the margins that were added to push the rows below the previously fixed header
      marginTop: '0 !important'
    },
    '&.MuiPaper-root': {
      border: 2,
      zIndex: 10000
    },
    '&.MuiDataGridPanel-root': {
      border: 2,
      zIndex: 10000
    },
    '& .MuiDataGrid-cell:focus-within': {
      outline: 'none !important'
    }
  };

  const columns: GridColDef<(typeof fields)[number]>[] = [
    {
      type: 'string',
      align: 'left',
      disableColumnMenu: true,
      disableExport: true,
      field: 'name',
      headerAlign: 'left',
      headerName: t('myFields').toUpperCase(),
      hideable: false,
      resizable: false,
      pinnable: false,
      flex: 1,
      renderCell: (params: GridRenderCellParams<Field>) => (
        <Box sx={{display: 'flex'}}>
          <GeometryThumbnail boundary={params.row.boundary}/>
          <span style={{textAlign: 'right', paddingLeft: '4px'}}>{params.row.name.toUpperCase()}</span>
        </Box>
      )
    },
    {
      type: 'string',
      align: 'right',
      disableColumnMenu: true,
      disableExport: true,
      field: 'area',
      resizable: false,
      minWidth: 200,
      headerAlign: 'center',
      headerName: t('area').toUpperCase(),
      hideable: false,
      pinnable: false,
      renderCell: (params: GridRenderCellParams<Field>) => (<span style={{textAlign: 'right', paddingRight: '20px'}}>{params.row.area.toFixed(2)} Ha</span>),
    }
  ];

  return <Box sx={{display: 'flex', height: '100%'}}>
    {
      fields && <DataGrid
        disableColumnMenu
        disableMultipleRowSelection
        hideFooter
        autosizeOnMount
        getRowId={(row) => row.uuid}
        sx={listStyle}
        rows={fields}
        onRowSelectionModelChange={handleSelectedField}
        rowSelectionModel={selectedField?.uuid || []}
        columns={columns}
        localeText={lang === 'es' ? 
          esES.components.MuiDataGrid.defaultProps.localeText:
          enUS.components.MuiDataGrid.defaultProps.localeText
        }
      />
    }
  </Box>;
};

export default FieldsTable;