import React, {FC, useEffect, useMemo, useRef, useState} from 'react';

import Map from '@geomatico/geocomponents/Map/Map';
import type {MapRef} from 'react-map-gl';
import maplibregl from 'maplibre-gl';
import {cogProtocol, locationValues} from '@geomatico/maplibre-cog-protocol';

import {FIELDS_SOURCE, INITIAL_VIEWPORT, unitsVariable} from '../../config';
import {SurveyDataType, SurveyDataUrls, SurveyVariable} from '../../domain/entities/Survey';
import {MapLayerMouseEvent} from 'mapbox-gl';
import {Feature, FeatureCollection, MultiPolygon} from 'geojson';

import bbox from '@turf/bbox';

import SurveySelectVariable from '../../components/SurveySelectVariable';
import SurveyDatePicker from '../../components/SurveyDatePicker';
import FormControl from '@mui/material/FormControl';
import {SxProps} from '@mui/material';
import {Field} from '../../domain/entities/Field';
import getMapSourcesAndLayers from '../../domain/useCases/getMapSourcesAndLayers';
import Tooltip from '../../components/Tooltip';

maplibregl.addProtocol('cog', cogProtocol);
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore needed for react-map-gl version compatibility.
// Was fixed in react-map-gl 7.0.24 but @geomatico/geocomponents needs to update its dependencies.
// TODO remove when future geocomponent release fixes https://github.com/visgl/react-map-gl/issues/2176
maplibregl.supported = () => true;

const selectsPosition: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  position: 'absolute',
  gap: 2,
  top: 30,
  left: 15
};

const asFeatureCollection = (fields: Array<Field>): FeatureCollection<MultiPolygon> => ({
  'type': 'FeatureCollection',
  'features': fields.map(field => ({
    'type': 'Feature',
    'properties': {
      'uuid': field.uuid,
      'ownerUuid': field.ownerUuid,
      'name': field.name,
      'area': field.area,
      'createdAt': field.createdAt,
      'updatedAt': field.updatedAt,
    },
    'geometry': field.boundary
  }))
});

export type MainContentProps = {
  mapStyle: string,
  fields: Array<Field>,
  selectedField?: Field,
  surveyDataUrls?: SurveyDataUrls,
  variables?: Array<SurveyVariable>,
  selectedVariable?: SurveyVariable,
  dates?: Array<Date>
  selectedDate?: Date,
  onVariableChange: (variable: SurveyVariable) => void,
  onDateChange: (date: Date) => void,
  onSelectedField: (field: Field) => void
};

const MainContent: FC<MainContentProps> = ({
  mapStyle,
  fields,
  selectedField,
  surveyDataUrls,
  variables,
  selectedVariable,
  dates,
  selectedDate,
  onVariableChange,
  onDateChange,
  onSelectedField
}) => {
  const mapRef = useRef<MapRef>(null);
  const [viewport, setViewport] = useState(INITIAL_VIEWPORT);

  const fieldsFeatureCollection = useMemo(() => fields && asFeatureCollection(fields), [fields]);

  const [sources, layers] = useMemo(
    () => getMapSourcesAndLayers(fieldsFeatureCollection, surveyDataUrls, selectedVariable),
    [fields, surveyDataUrls, selectedVariable]);

  const [tooltipValue, setTooltipValue] = useState(Number.NaN);
  const [tooltipPosition, setTooltipPosition] = useState<{ top: number, left: number }>();

  const unit = unitsVariable && unitsVariable.filter(item => item.variable === selectedVariable)[0]?.unit;

  useEffect(() => {
    require('maplibre-gl/dist/maplibre-gl.css');
    require('./Map.css');
  }, []);

  useEffect(() => {
    if (!selectedField) return;
    const selectedGeojsonField: Feature<MultiPolygon> | undefined = fieldsFeatureCollection?.features.filter(field => field.properties?.uuid === selectedField.uuid)[0];
    if (!selectedGeojsonField) return;
    const [minX, minY, maxX, maxY] = selectedGeojsonField && bbox(selectedGeojsonField);

    if (minX && minY && maxX && maxY && mapRef.current && 'fitBounds' in mapRef.current) {
      mapRef.current?.fitBounds([[minX, minY], [maxX, maxY]],
        {
          duration: 1000,
          padding: {
            left: 50,
            right: 50,
            top: 80,
            bottom: 80
          }
        }
      );
    }
  }, [selectedField, fields]);

  const handleMapClick = (e: MapLayerMouseEvent) => {
    const feature = e.features && e.features[0];
    const field = fields.find(field => field.uuid === feature?.properties?.uuid);
    if(field) onSelectedField(field);
  };

  const handleMouseMove = (event: MapLayerMouseEvent) => {
    setTooltipPosition({left: event.originalEvent.pageX, top: event.originalEvent.pageY});

    const cogUrl = surveyDataUrls?.urls
      .find(url => url.variable === selectedVariable && url.type === SurveyDataType.COG)?.url;
    if (cogUrl) {
      locationValues(cogUrl, {latitude: event.lngLat.lat, longitude: event.lngLat.lng}, mapRef.current?.getZoom())
        .then(values => setTooltipValue(values?.length ? values[0] : Number.NaN))
        .catch(error => {
          setTooltipValue(Number.NaN);
          console.error(error);
        });
    }
  };

  return <Map
    ref={mapRef}
    mapLib={maplibregl}
    mapStyle={mapStyle}
    viewport={viewport}
    onViewportChange={setViewport}
    onClick={handleMapClick}
    onMouseMove={handleMouseMove}
    sources={sources}
    layers={layers}
    interactiveLayerIds={[`${FIELDS_SOURCE}-fill`]}
    attributionControl={true}
  >
    {
      variables && dates && selectedVariable && selectedDate && <FormControl sx={selectsPosition}>
        <SurveySelectVariable variables={variables} selectedVariable={selectedVariable}
          onVariableChange={onVariableChange}/>
        <SurveyDatePicker availableDates={dates} selectedDate={selectedDate} onDateChange={onDateChange}/>
      </FormControl>
    }
    {!Number.isNaN(tooltipValue) && tooltipPosition &&
      <Tooltip {...tooltipPosition}>{tooltipValue.toFixed(2)}{unit}</Tooltip>
    }
  </Map>;

};

export default MainContent;
