import RepositoryMapper from '../../common/RepositoryMapper';
import {Field, FieldCreation} from '../../domain/entities/Field';
import {FieldDto, FieldDtoCreation} from './FieldDto';

const fieldMapper: RepositoryMapper<Field, FieldDto> = {
  toDomain: (dto: FieldDto): Field => {
    return {
      ...dto,
      createdAt: new Date(dto.createdAt),
      updatedAt: new Date(dto.updatedAt)
    };
  },
  toDto: (domain: Field): FieldDto => {
    return {
      ...domain,
      createdAt: domain.createdAt.toISOString(),
      updatedAt: domain.updatedAt.toISOString()
    };
  },
  toDtoCreation: (domain: FieldCreation): FieldDtoCreation => domain
};

export default fieldMapper;