import React, {FC} from 'react';
import MapLegend from '@geomatico/geocomponents/Map/MapLegend';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

import {HEXColor} from '../domain/entities/HEXColor';

export type LegendProps = {
  label?: string,
  min?: number,
  max?: number
  thresholds: Array<number>,
  colors: Array<HEXColor>,
  isVertical?: boolean
};

const Legend: FC<LegendProps> = ({label, min, max, thresholds, colors, isVertical=true}) => {

  const legendStyles = {
    display: 'inline-flex',
    flexDirection:'column',
    alignSelf: 'start',
    pr: 2,
    pt: 1,
    pl: 1,
    opacity: 0.85,
    position: 'absolute',
    bottom: 10,
    left: 10
  };

  const mapLegendSx = {
    '&.MapLegend-root': {
      display: 'flex',
      flexDirection: isVertical ? 'column' : 'row'
    },
    '& .MapLegend-swatch': {
      '& .MuiBox-root': {
        borderRadius: '2px',
        height: 20,
        width: 25
      }
    },
    '& .MapLegend-text': {
      fontSize: '0.75rem',
      lineHeight: 2.66,
      letterSpacing: '0.08333em',
      textTransform: 'uppercase',
      whiteSpace: 'nowrap'
    }
  };

  if(colors.length !== thresholds.length + 1) {
    throw new Error(`colors needs to have ${thresholds.length + 1} elements and only has ${colors.length}`);
  }

  if(min && min > Math.min(...thresholds)) {
    throw new Error(`min needs to be less than ${Math.min(...thresholds)} and it is ${min}`);
  }

  if(max && max < Math.max(...thresholds)) {
    throw new Error(`max needs to be greater than ${Math.max(...thresholds)} and it is ${max}`);
  }

  const fields = colors.map((color, index) => (
    {
      name: index === 0
        ? min ? `${min} - ${thresholds[index]}` : `< ${thresholds[index]}`
        : index === colors.length - 1
          ? max ? `${thresholds[index-1]} - ${max}` : `> ${thresholds[index-1]}`
          : `${thresholds[index-1]} - ${thresholds[index]}`,
      type: 'fill',
      paint: {
        color,
        outlineColor: 'lightgrey',
        outlineWidth: 1
      }
    }
  ));

  return <Paper sx={legendStyles}>
    {label ? <Typography variant='subtitle2' sx={{pl: 1}}>{label}</Typography> : null}
    <MapLegend fields={fields} sx={mapLegendSx}/>
  </Paper>;
};

export default Legend;
