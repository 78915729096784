import {VersionedEntityDto, VersionedEntityDtoCreation} from './VersionedEntityDto';
import {VersionedEntity, VersionedEntityCreation} from './VersionedEntity';
import EntityHttp from './Http';
import RepositoryMapper from './RepositoryMapper';

/**
 * Base class for @Repository classes. By knowing about domain and dto objects it encapsulates boilerplate about
 * making http calls and transforming between dto and domain objects.
 */
class BaseRepository<TDomain extends VersionedEntity, TDto extends VersionedEntityDto> {
  constructor(
    protected mapper: RepositoryMapper<TDomain, TDto>,
    protected http: EntityHttp,
    protected apiEntityName: string
  ) {
  }

  protected async doList(queryParams: Record<string, string>, headers?: Record<string, string>): Promise<TDomain[]> {
    const dtos = await this.http.get<TDto[]>({
      path: this.apiEntityName,
      queryParams,
      headers
    });
    return dtos.map(this.mapper.toDomain);
  }

  async retrieve(id: string | number, headers?: Record<string, string>): Promise<TDomain> {
    const dto = await this.http.get<TDto>({
      path: `${this.apiEntityName}/${id}`,
      headers
    });
    return this.mapper.toDomain(dto);
  }

  async create(entity: VersionedEntityCreation<TDomain>): Promise<TDomain> {
    const dto = await this.http.post<VersionedEntityDtoCreation<TDto>, TDto>({
      path: this.apiEntityName,
      body: this.mapper.toDtoCreation(entity)
    });
    return this.mapper.toDomain(dto);
  }

  async replace(entity: TDomain): Promise<TDomain> {
    const dto = await this.http.put<TDto, TDto>({
      path: this.apiEntityName,
      body: this.mapper.toDto(entity)
    });
    return this.mapper.toDomain(dto);
  }

  async delete(id: string | number): Promise<void> {
    await this.http.delete({
      path: `${this.apiEntityName}/${id}`,
    });
  }
}

export default BaseRepository;