import {HttpError} from '../../common/jsonHttp';
import {t} from 'i18next';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isHttpError(e: any): e is HttpError {
  return e.statusCode !== undefined && typeof e.statusCode === 'number';
}

const getUserFriendlyApiErrorMessage = (e: Error): string => {
  if (isHttpError(e) && e.statusCode === 403) {
    return t('HTTP_403');
  } else {
    return '';
  }
};

export default getUserFriendlyApiErrorMessage;
