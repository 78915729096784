import {RGBA} from '../entities/RGBA';
import {HEXColor} from '../entities/HEXColor';

const hexToRGBA = (hexColor: HEXColor): RGBA => {
  let hexStr = hexColor.replace(/^#/, ''); // Remove initial hash if present

  if (hexStr.length === 3) {
    hexStr = hexStr.split('').map(char => char + char).join(''); // Expand 3 to 6 characters (#RGB -> #RRGGBB)
  } else if (hexStr.length !== 6) {
    throw new Error(`colorBreaks color value '${hexColor}' is not valid, should use #RRGGBB hexadecimal color notation`);
  }

  const rgba: RGBA = [
    parseInt(hexStr.substring(0, 2), 16), // R
    parseInt(hexStr.substring(2, 4), 16), // G
    parseInt(hexStr.substring(4, 6), 16), // B
    255 // A is always fully opaque
  ];

  if (rgba.some(number => isNaN(number))) {
    throw new Error(`colorBreaks color value '${hexColor}' is not valid, should use #RRGGBB hexadecimal color notation`);
  }

  return rgba;
};

export default hexToRGBA;