import BaseRepository from '../../common/BaseRepository';
import {Survey, SurveyDataUrls} from '../../domain/entities/Survey';
import {SurveyDataUrlsDto, SurveyDto} from './SurveyDto';
import surveyMapper, {toDomainDataUrls} from './surveyMapper';
import {TalptechApiHttp} from '../../services/TalptechApiHttp';
import {Repository} from '../../common/injection';

@Repository
export default class SurveyRepository extends BaseRepository<Survey, SurveyDto> {
  constructor(http: TalptechApiHttp) {
    super(surveyMapper, http, 'surveys');
  }

  list(fieldUuids: string[]): Promise<Survey[]> {
    return this.doList({fieldUuid: fieldUuids.join(',')});
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async replace(entity: Survey): Promise<Survey> {
    throw new Error('Unsupported operation: cannot modify surveys');
  }

  async getDataUrls(surveyUuid: string): Promise<SurveyDataUrls> {
    const dto = await this.http.get<SurveyDataUrlsDto>({
      path: `${this.apiEntityName}/${surveyUuid}/data-urls`
    });
    return toDomainDataUrls(dto);
  }
}
