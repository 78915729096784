import React, {FC} from 'react';

import {SurveyVariable} from '../domain/entities/Survey';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import {SelectChangeEvent} from '@mui/material';
import FormControl from '@mui/material/FormControl';

const MenuProps: object = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center'
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center'
  },
  getcontentanchorel: null,
  sx: {
    '& .MuiList-root': {
      p: 0,
      color: 'grey.700',
      bgcolor: 'background.paper',

    },
    '& .SelectInput-placeholder': {
      color: 'grey.500',
      fontStyle: 'italic',
      textTransform: 'uppercase'
    },
    '& .MuiMenuItem-root': {
      padding: '8px 14px',
    }
  }
};

export type SurveySelectVariableProps = {
  variables: Array<SurveyVariable>,
  selectedVariable: SurveyVariable,
  onVariableChange: (variable: SurveyVariable) => void
};

const SurveySelectVariable: FC<SurveySelectVariableProps>  = ({ variables, selectedVariable, onVariableChange }) => {

  const handleVariableChange = (e: SelectChangeEvent) => onVariableChange(e.target.value as SurveyVariable);

  return <FormControl fullWidth size='small' sx={{minWidth: 200, opacity: 0.75}}>
    <Select
      MenuProps={MenuProps}
      fullWidth
      value={selectedVariable}
      onChange={handleVariableChange}
      sx={{ bgcolor: 'white' }}
    >
      {variables.map((variable, i)=> (
        <MenuItem key={i} value={variable}>
          {variable}
        </MenuItem>
      ))}
    </Select>
  </FormControl>;
};

export default SurveySelectVariable;