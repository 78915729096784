import {SurveyDataUrls} from '../entities/Survey';
import {inject} from '../../common/injection';
import SurveyRepository from '../../repository/survey/SurveyRepository';

const getSurveyDataUrls = async (surveyUuid?: string): Promise<SurveyDataUrls | undefined> => {
  if (!surveyUuid) return;
  const repository = inject(SurveyRepository);
  return await repository.getDataUrls(surveyUuid);
};

export default getSurveyDataUrls;