import Http, {BaseUrlAware} from '../common/Http';
import {DeleteRequest, destroy, get, GetRequest, post, PostRequest, put, PutRequest} from '../common/jsonHttp';
import {HttpService} from '../common/injection';
import {API_BASE_URL} from '../config';

const KEY_ACCESS_TOKEN = 'ACCESS_TOKEN';

const TOKENS = new Map<string, string>();

export const setAuthTokens = (accessToken: string) => {
  // they say it never expires
  TOKENS.set(KEY_ACCESS_TOKEN, accessToken);
};

@HttpService
export class TalptechApiHttp implements Http {
  async get<ResponseType>(request: BaseUrlAware<GetRequest>): Promise<ResponseType> {
    return get({
      ...request,
      baseUrl: API_BASE_URL,
      headers: {
        ...request.headers,
        authorization: `Bearer ${TOKENS.get(KEY_ACCESS_TOKEN)}`,
      }
    });
  }

  post<RequestType, ResponseType>(request: BaseUrlAware<PostRequest<RequestType>>): Promise<ResponseType> {
    return post({
      ...request,
      baseUrl: API_BASE_URL,
      headers: {
        ...request.headers,
        authorization: `Bearer ${TOKENS.get(KEY_ACCESS_TOKEN)}`,
      }
    });
  }

  put<RequestType, ResponseType>(request: BaseUrlAware<PutRequest<RequestType>>): Promise<ResponseType> {
    return put({
      ...request,
      baseUrl: API_BASE_URL,
      headers: {
        ...request.headers,
        authorization: `Bearer ${TOKENS.get(KEY_ACCESS_TOKEN)}`,
      }
    });
  }

  delete(request: BaseUrlAware<DeleteRequest>): Promise<void> {
    return destroy({
      ...request,
      baseUrl: API_BASE_URL,
      headers: {
        ...request.headers,
        authorization: `Bearer ${TOKENS.get(KEY_ACCESS_TOKEN)}`,
      }
    });
  }
}
