import React, {FC} from 'react';
import ScreenshotMap from '../components/ScreenshotMap';
import parseColorBreaks from '../domain/useCases/parseColorBreaks';
import parse4326Bbox from '../domain/useCases/parse4326Bbox';
import parseScreenshotQueryParameters from '../domain/useCases/parseScreenshotQueryParameters';
import Alert from '@mui/material/Alert';

const ScreenshotView: FC = () => {

  try {
    const {cogUrl, bbox: bboxParam, legendTitle, colorBreaks} = parseScreenshotQueryParameters(window.location.href);

    const bbox = bboxParam ? parse4326Bbox(bboxParam) : undefined;
    const {thresholds, colors} = parseColorBreaks(colorBreaks);

    return <ScreenshotMap
      cogUrl={cogUrl}
      bbox={bbox}
      legendTitle={legendTitle}
      thresholds={thresholds}
      colors={colors}
    />;

  } catch (e) {
    const message = e instanceof Error ? e.message : 'Unknown cause';

    return <Alert severity='error'>Error: {message}</Alert>;
  }

};

export default ScreenshotView;
