import BaseRepository from '../../common/BaseRepository';
import fieldMapper from './fieldMapper';
import {TalptechApiHttp} from '../../services/TalptechApiHttp';
import {Repository} from '../../common/injection';
import {FieldDto} from './FieldDto';
import {Field} from '../../domain/entities/Field';

@Repository
export default class FieldRepository extends BaseRepository<Field, FieldDto> {
  constructor(http: TalptechApiHttp) {
    super(fieldMapper, http, 'fields');
  }

  list(): Promise<Field[]> {
    return this.doList({});
  }
}