import {cogProtocol} from '@geomatico/maplibre-cog-protocol';
import {TileJSON} from '@geomatico/maplibre-cog-protocol/dist/types';
import {BBOX} from '../entities/BBOX';

const getCogBbox = (cogUrl: string): Promise<BBOX> => {
  return cogProtocol({url: cogUrl, type: 'json'})
    .then(response => response.data as TileJSON)
    .then(tileJSON => tileJSON.bounds as BBOX);
};

export default getCogBbox;
