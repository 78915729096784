import React, {FC} from 'react';

import Stack from '@mui/material/Stack';

import Box from '@mui/material/Box';
import styled from '@mui/styles/styled';


import GeomaticoLink from '../../components/GeomaticoLink';
import FieldsTable from '../../components/FieldsTable';

import {Field} from '../../domain/entities/Field';

const ScrollableContent = styled(Box)({
  overflow: 'auto',
  padding: '8px',
});

const stackSx = {
  height: '100%',
  overflow: 'hidden'
};

export type SidePanelContentProps = {
  fields: Array<Field>,
  selectedField?: Field,
  onSelectedField: (field: Field) => void
};


const SidePanelContent: FC<SidePanelContentProps> = ({fields, selectedField, onSelectedField}) =>
  <Stack sx={stackSx}>
    <ScrollableContent>
      <FieldsTable fields={fields} selectedField={selectedField} onSelectedField={onSelectedField}/>
    </ScrollableContent>
    <GeomaticoLink/>
  </Stack>;

export default SidePanelContent;
